import React from "react";
import CustomButton from "../forms/CustomButton";
import CustomInput from "../forms/CustomInput";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, showModal } from "../../../app/home/HomeAction";
import { West, Close } from "@mui/icons-material";
import { toast } from "react-toastify";
import postLoginInstance from "../../PostLoginAxios";
import dayjs from "dayjs";

const EditMember = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.HomeState);
  const [loading, setLoading] = React.useState(false);

  const [memberdata, setMemberData] = React.useState({
    name: user?.name || "",
    contactPerson: user?.contactPerson || "",
    designation: user?.designation || "",
    brochureUrl: user?.brochureUrl || "",
    brouchre: "",
    phone: user?.phone || "",
    profileImageUrl: user?.profileImageUrl || "",
    profileImage: "",
    email: user?.email || "",
    incorporationDate: user?.incorporationDate || dayjs().format("DD/MM/YYYY"),
    companyRegistrationNumber: user?.companyRegistrationNumber || "",
    gstNo: user?.gstNo || "",
    companyDescription: user?.companyDescription || "",
  });

  const [dispalyImage, setDisplayImage] = React.useState(null);

  // Handle profile image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageSize = file.size / 1024 / 1024; // in MB

      if (imageSize > 5) {
        toast.error("Image size should not exceed 5 MB");
        return;
      }

      const img = new Image();
      const objectURL = URL.createObjectURL(file); // Create the URL here

      img.src = objectURL;

      img.onload = () => {
        const { width, height } = img;
        if (width === 306 && height === 165) {
          setDisplayImage(objectURL); // Set display image with the URL
          setMemberData((prevState) => ({
            ...prevState,
            profileImageUrl: "", // Reset existing URL
            profileImage: file, // Set the new image file
          }));
        } else {
          toast.error("Image dimensions should be 306x165 pixels.");
        }
      };

      img.onerror = () => {
        toast.error("Failed to load the image");
      };
    }
  };
  // Handle brochure change
  const handleBrochureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const brochureSize = file.size / 1024 / 1024; // in MB

      if (brochureSize > 25) {
        toast.error("Brochure size should not exceed 25 MB");
        return;
      }

      setMemberData({
        ...memberdata,
        brochureUrl: "",
        brouchre: file,
      });
    }
  };

  const clearBrochureUrl = () => {
    setMemberData({
      ...memberdata,
      brochureUrl: "",
      brouchre: "",
    });
  };

  const clearProfileImage = () => {
    setMemberData({
      ...memberdata,
      profileImageUrl: "",
      profileImage: "",
    });
  };

  const validation = (data) => {
    if (!data.contactPerson) {
      toast.info("Contact Person is required");
      return false;
    }
    if (!data.designation) {
      toast.info("Designation is required");
      return false;
    }
    if (!data.phone) {
      toast.info("Phone is required");
      return false;
    }
    if (!data.incorporationDate) {
      toast.info("Incorporation Date is required");
      return false;
    }
    if (!data.companyRegistrationNumber) {
      toast.info("Company Registration Number is required");
      return false;
    }
    if (!data.gstNo) {
      toast.info("GST No is required");
      return false;
    }
    if (!data.companyDescription) {
      toast.info("Company Description is required");
      return false;
    }
    if (!data.companyDescription.length > 200) {
      toast.info("Company Description should be less than 200 characters");
      return false;
    }

    if (!data.profileImage && !data.profileImageUrl) {
      toast.info("Profile Image is required");
      return false;
    }
    if (data.phone.length !== 10) {
      toast.info("Phone number should be of 10 digits");
      return false;
    }
    return true;
  };

  const handleUpdateProfile = async () => {
    setLoading(true);
    if (!validation(memberdata)) return;
    let formData = new FormData();
    if (memberdata.brouchre) {
      formData.append("brochure", memberdata.brouchre);
    } else if (memberdata.brochureUrl) {
      formData.append("brochureUrl", memberdata.brochureUrl);
    }
    if (memberdata.profileImage) {
      formData.append("profileImage", memberdata.profileImage);
    } else {
      formData.append("profileImageUrl", memberdata.profileImageUrl);
    }
    formData.append("contactPerson", memberdata.contactPerson);
    formData.append("designation", memberdata.designation);
    formData.append("phone", memberdata.phone);
    formData.append("incorporationDate", memberdata.incorporationDate);
    formData.append(
      "companyRegistrationNumber",
      memberdata.companyRegistrationNumber
    );
    formData.append("gstNo", memberdata.gstNo);
    formData.append("companyDescription", memberdata.companyDescription);

    try {
      await postLoginInstance.patch("/users/update-details", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(getUserProfile());
    } catch (error) {
      toast.error("Failed to update profile");
    } finally {
      dispatch(showModal(null));
      setLoading(false);
    }
  };

  return (
    <div className="edit-member-main">
      <Col>
        <Row>
          <div className="flex-row gap-12 align-center">
            <div className="f-s-24 manrope-semibold pointer flex-row align-center">
              <div
                className="flex-row align-center"
                onClick={() => dispatch(showModal(null))}
              >
                <West sx={{ fontSize: "20px" }} />
              </div>
            </div>
            <p className="f-s-16 manrope-medium">Edit Profile</p>
          </div>
        </Row>
        <Row>
          <div className="flex-row justify-center p-4 mb-3">
            <div className="welcome-image position-relative">
              <img
                src={
                  dispalyImage ||
                  memberdata.profileImageUrl ||
                  `https://ui-avatars.com/api/?name=${memberdata.name}&background=random`
                }
                alt=""
              />
              <div
                className="welcome-picker"
                onClick={() =>
                  document.getElementById("profileImageInput").click()
                }
              >
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.5 18.5H21.5V20H0.5V18.5ZM18.05 5.75C18.65 5.15 18.65 4.25 18.05 3.65L15.35 0.95C14.75 0.35 13.85 0.35 13.25 0.95L2 12.2V17H6.8L18.05 5.75ZM14.3 2L17 4.7L14.75 6.95L12.05 4.25L14.3 2ZM3.5 15.5V12.8L11 5.3L13.7 8L6.2 15.5H3.5Z"
                    fill="white"
                  />
                </svg>
              </div>
              {memberdata.profileImageUrl && (
                <Close
                  className="position-absolute top-0 end-0 cursor-pointer"
                  onClick={clearProfileImage}
                />
              )}
            </div>
            {/* Invisible input for image upload */}
            <input
              id="profileImageInput"
              type="file"
              accept="image/*"
              className="d-none"
              onChange={handleImageChange}
            />
          </div>
        </Row>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={6}>
                <div className="mx-md-4 my-2">
                  <CustomInput
                    heading={"Contact Person"}
                    placeholder={"Enter Name"}
                    value={memberdata.contactPerson}
                    onChange={(e) =>
                      setMemberData({
                        ...memberdata,
                        contactPerson: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mx-md-4 my-2">
                  <CustomInput
                    heading={"Designation"}
                    placeholder={"Enter Designation"}
                    value={memberdata.designation}
                    onChange={(e) =>
                      setMemberData({
                        ...memberdata,
                        designation: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mx-md-4 my-2">
                  <CustomInput
                    disabled
                    heading={"Email"}
                    value={memberdata.email}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mx-md-4 my-2">
                  <CustomInput
                    heading={"Phone"}
                    value={memberdata.phone}
                    onChange={(e) =>
                      setMemberData({
                        ...memberdata,
                        phone: e.target.value,
                      })
                    }
                    type={"number"}
                    placeholder={"Enter Phone No"}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mx-md-4 my-2">
                  <CustomInput
                    heading={"Date of Incorporation"}
                    type={"date"}
                    value={memberdata.incorporationDate}
                    onChange={(e) =>
                      setMemberData({
                        ...memberdata,
                        incorporationDate: e,
                      })
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mx-md-4 my-2">
                  <CustomInput
                    heading={"Registration No"}
                    value={memberdata.companyRegistrationNumber}
                    onChange={(e) =>
                      setMemberData({
                        ...memberdata,
                        companyRegistrationNumber: e.target.value,
                      })
                    }
                    type={"number"}
                    placeholder={"Enter Phone No"}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mx-md-4 my-2">
                  <CustomInput
                    heading={"GST No"}
                    value={memberdata.gstNo}
                    onChange={(e) =>
                      setMemberData({
                        ...memberdata,
                        gstNo: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mx-md-4 my-2">
                  {memberdata.brochureUrl ? (
                    <div>
                      <h3 className="f-s-14">Brouchre</h3>
                      <div className="d-flex align-items-center mt-4">
                        <a
                          href={memberdata.brochureUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="blue underline"
                        >
                          View Brochure
                        </a>
                        <Close
                          className="cursor-pointer ms-2"
                          onClick={clearBrochureUrl}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h3 className="f-s-14">Brochure</h3>
                      <div className="d-flex mt-4 align-items-center">
                        <input
                          type="file"
                          accept=".pdf"
                          className="input"
                          id="brochureInput"
                          onChange={handleBrochureChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="mx-md-4 my-2">
                  <CustomInput
                    heading={"About Company"}
                    type={"text-area"}
                    maxLength={300}
                    placeholder={
                      "Enter your company description in brief (max 500 characters)"
                    }
                    error={
                      memberdata.companyDescription.length > 200 && (
                        <p className="error">
                          Company Description should be less than 200 characters
                        </p>
                      )
                    }
                    value={memberdata.companyDescription}
                    onChange={(e) =>
                      setMemberData({
                        ...memberdata,
                        companyDescription: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <CustomButton
                    className={"primary-btn my-4"}
                    disabled={loading}
                    onClick={handleUpdateProfile}
                  >
                    {loading ? <Spinner /> : "Save Changes"}
                  </CustomButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default EditMember;
