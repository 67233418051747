import React from "react";
import Main from "./components/Main";
import AppInterface from "../../utils/commonComponents/AppInterface";

const ComponentName = () => {
  return (
    <AppInterface
      header={
        <div className="networking-header flex-row gap-12 align-center">
          <div>
            <h1 className="f-s-16 manrope-bold">Networking</h1>
          </div>
        </div>
      }
      className={" networking-interface"}
    >
      <Main />
    </AppInterface>
  );
};

export default ComponentName;
