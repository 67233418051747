import { CLEAR_ERRORS, FORGOT_PASSWORD_FAILURE, LOGOUT, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, RESET_PASSWORD_FAILURE, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS } from "./ActionTypes";
import { API_URL } from "../../utils/Constant";
import { deleteToken } from "../../utils/ManageToken";
import { toast } from "react-toastify";
import preloginInstance from "../../utils/PreLoginAxios"
import { getUserProfile } from "../home/HomeAction";
import postLoginInstance from "../../utils/PostLoginAxios";






export const login = (data) => async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    try {
        const res = await preloginInstance.post(`users/login`, { email: data.email, password: data.password });
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        dispatch(getUserProfile())
    }
    catch (err) {
        dispatch({ type: LOGIN_FAILURE, payload: err.response.data.message });
        toast.error(err.response.data.message || "Something went Wrong!");
        dispatch(clearErrors());
    }
}

export const forgotPasswordSendLink = (data) => async (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    try {
        const res = await preloginInstance.post(`/users/login/send-link`, { email: data.email });
        dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: res.data });
        toast.success(res.data.message || "Link sent successfully!");
    }
    catch (err) {
        dispatch({ type: FORGOT_PASSWORD_FAILURE, payload: err.response.data.message });
        toast.error(err.response.data.message || "Something went Wrong!");
        dispatch(clearErrors());
    }
}

export const resetPassword = (data) => async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    try {
        const config = {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        };

        const res = await preloginInstance.post(`/users/login/reset-password`,
            { newPassword: data.password }, config);

        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: res.data });

        toast.success(res.data.message || "Password reset successfully!");
    } catch (err) {
        dispatch({ type: RESET_PASSWORD_FAILURE, payload: err.response?.data?.message || "Error" });
        toast.error(err.response?.data?.message || "Something went wrong!");

        dispatch(clearErrors());
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}

export const logout = () => async (dispatch) => {
    dispatch({ type: LOGOUT });
    await postLoginInstance.patch("/notifications/subscribe")
    deleteToken();
}




export const getVidoeAds = async () => {
    try {
        const res = await preloginInstance.get(`/video-banner/current`);
        return res.data;
    } catch (err) {
    }
}