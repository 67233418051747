import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDefaultImgUrl } from "../../../utils/Constant";

const Recommendations = () => {
  const { recommendations } = useSelector((state) => state.HomeState);
  const navigate = useNavigate();
  return (
    <div>
      <Col className="recommendations-main pb-0">
        <Row>
          <Col>
            <div className="flex-row align-center justify-space-between recommend-head">
              <h2 className="f-s-16 manrope-bold">Recommendations</h2>
              <p className="f-s-12 manrope-regular pointer blue-0E1 underline" onClick={() => navigate("/networking")}>
                View All
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="recommend-container">
              {recommendations.map((recommendation, index) => (
                <RecommendationsCard key={index} recommendation={recommendation} />
              ))}
              {recommendations.length < 1 && (
                <div className="empty-recommend flex-row align-center justify-center py-4 my-4">
                  <p className="f-s-12 manrope-regular">No Recommendations</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default Recommendations;

const RecommendationsCard = ({ recommendation }) => {
  const navigate = useNavigate()

  return (
    <div className="recommendation-card flex-row justify-space-between ">
      <div className="  content-group">
        <div >
          <div className="recommendation-img">
            <img src={recommendation.profileImageUrl ? recommendation.profileImageUrl : getDefaultImgUrl(recommendation.firstName || recommendation.company.companyName)} alt="profile" />
          </div>
        </div>
        <div>
          <div className="gap-2 recommed-detail flex-col align-start justify-center h-full">
            <h5 className="f-s-14 manrope-medium">{recommendation.firstName || "New User"}</h5>
            <div className="f-s-12 manrope-regular ">{recommendation.company.companyName}</div>
          </div>
        </div>
      </div>
      <div className="p-0">
        <div className="text-center h-full flex-row justify-center align-center">
          <CustomButton onClick={() => navigate(`/profile/${recommendation.uuid}`)} className={"common-btn manrope-regular"}>
            View Profile
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
