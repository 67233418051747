import { useMediaQuery } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DesktopHeader from "./interface/desktop/Header";
import MobileHeader from "./interface/mobile/Header";
import { mobileRoutes, modals } from "../Constant";
import { useLocation, useNavigate } from "react-router-dom";
import BasicModal from "./Modal";
import { showModal } from "../../app/home/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import AddToHomeScreen from "../AddToHomeScreen";

const AppInterface = ({ children, header, hideNavigation, hideSearch, className }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return isMobile ? (
    <>
      <MobileInterface className={className} hideNavigation={hideNavigation} header={header}>
        {children}
      </MobileInterface>
    </>
  ) : (
    <>
      <DesktopInterface className={className} hideSearch={hideSearch}>{children}</DesktopInterface>
    </>
  );
};

export default AppInterface;

const DesktopInterface = ({ children, hideSearch, className }) => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.HomeState);
  return (
    <>
      {modals.HOME_NOTIFICATION_MODAL === modal && (
        <BasicModal
          className={"notification-main-modal"}
          open={modal}
          hideCloseButton
          closeOnBackdropClick={true}
          handleClose={() => dispatch(showModal(null))}
        />
      )}
      <div className={`desktop-interface`}>
        <div className="p-0">
          <DesktopHeader hideSearch={hideSearch} />
        </div>
        <div className={`desktop-main ${className}`}>{children}</div>
      </div>
    </>
  );
};

const MobileInterface = ({ children, header, hideNavigation = false, className }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.HomeState);
  return (
    <>
      {modals.HOME_NOTIFICATION_MODAL === modal && (
        <BasicModal
          className={"notification-main-modal"}
          open={modal}
          hideCloseButton
          closeOnBackdropClick={true}
          handleClose={() => dispatch(showModal(null))}
        />
      )}

      <div className={`mobile-interface`}>
        {header || <MobileHeader />}
        <div className={`mobile-main ${className}`}>{children}</div>
        {!hideNavigation && (
          <div className="mobile-footer">
            <div className="flex-row align-center justify-space-between">
              {mobileRoutes?.map((route, index) => (
                <div key={index} className="flex-col align-center" onClick={() => { navigate(route.route) }}>
                  <div>
                    <img src={location.pathname === route.route ? route?.currentSvg : route?.svg} alt={route?.name} />
                  </div>
                  <p
                    className={`f-s-12 manrope-regular ${location.pathname === route.route ? "blue-0E1" : "black"
                      }`}
                  >
                    {route?.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
