import React, { useEffect } from "react";
import ProfileCard from "./components/ProfileCard";
import AppInterface from "../../utils/commonComponents/AppInterface";
import { West } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import CustomButton from "../../utils/commonComponents/forms/CustomButton";
import { showModal } from "../home/HomeAction";
import { getDefaultImgUrl, modals } from "../../utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../utils/commonComponents/Modal";
import { getMemberbyId } from "./ProfileAction";
import { toast } from "react-toastify";
import { CLEAR_ERRORS } from "./ActionTypes";

const ViewProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { modal, user } = useSelector((state) => state.HomeState);
  const params = useParams()
  const { error, member } = useSelector((state) => state.ProfileState);
  useEffect(() => {
    if (params.id)
      dispatch(getMemberbyId({ uuid: params.id }));
    else
      navigate(-1);
  }
    , [params]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      navigate(-1);
      dispatch({ type: CLEAR_ERRORS })
    }
  }, [error, dispatch, navigate]);

  useEffect(() => {
    if (member && user && user?.user?.uuid === member?.user?.uuid) {
      navigate("/profile");
    }
  }, [member, user, navigate]);

  useEffect(() => {
  }, [dispatch]);
  return (
    <>
      {modal === modals.BOOK_MEEETING_MODAL && (
        <BasicModal
          open={modals.BOOK_MEEETING_MODAL}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          data={{ uuid: member?.user?.uuid }}
          className={"edit-member-modal"}
          hideCloseButton={isMobile}
        />
      )}
      <AppInterface
        header={
          <div className="networking-header flex-row gap-12 align-center">
            <div className="pointer" onClick={() => navigate(-1)}>
              <West></West>
            </div>
            <div>
              <h1 className="f-s-16 manrope-bold">{member?.firstName || "New user"} {member?.lastName || ""}</h1>
            </div>
          </div>
        }
        hideNavigation
      >
        <div className="my-profile-container container">
          {!isMobile && (
            <div className="networking-header flex-row gap-12 align-center mb-3">
              <div className="pointer" onClick={() => navigate(-1)}>
                <West sx={{ fontSize: "24px" }}></West>
              </div>
              <div>
                <h1 className="f-s-24 manrope-bold">{member?.firstName || "New user"} {member?.lastName || ""}</h1>
              </div>
            </div>
          )}
          <ProfileCard
            img={member?.profileImageUrl ? member?.profileImageUrl : getDefaultImgUrl(member?.firstName || member?.company?.companyName)}
            heading={`${member?.firstName || "New user"} ${member?.lastName || ""}`}
            text={`${member?.designation} | ${member?.company?.companyName}`}
            phone={member?.phone ? member?.phone : <p className="blur">1234567890</p>}
            email={member?.user?.email ? member?.user?.email : <p className="blur">xyz@email.com</p>}
            tip={member?.phone ? "" : "Request a meeting to view the details*"}
          ></ProfileCard>
          <div className="my-profile-btn flex-row gap-10">
            <CustomButton
              className={"primary-btn"}
              onClick={() => dispatch(showModal(modals.BOOK_MEEETING_MODAL))}
            >
              Book a Meeting
            </CustomButton>
          </div>
        </div>
      </AppInterface>
    </>
  );
};

export default ViewProfile;
