import React from "react";
import ReferInvite from "../../home/components/ReferInvite";
import { useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { modals } from "../../../utils/Constant";
import BasicModal from "../../../utils/commonComponents/Modal";
import { showModal } from "../../home/HomeAction";

const Main = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();

  const { modal } = useSelector((state) => state.HomeState);
  return (
    <>
      {modal === modals.INVITE_REFER_MODAL && (
        <BasicModal
          className={"invite-modal"}
          open={modal}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          back={() => dispatch(showModal(null))}
          hideCloseButton={isMobile}
        />
      )}
      {modal === modals.INVITE_CO_MEMBER_MODAL && (
        <BasicModal
          open={modal}
          className={"invite-modal"}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          back={() => dispatch(showModal(null))}
          hideCloseButton={isMobile}
        />
      )}
      <div className="invite-main-container container mt-2">
        {!isMobile && (
          <div className="networking-header flex-row gap-12 align-center mb-3">
            {/* <div className="pointer" onClick={() => navigate(-1)}>
              <WestIcon sx={{ fontSize: "24px" }}></WestIcon>
            </div> */}
            <div>
              <h1 className="f-s-24 manrope-bold">Refer and Invite</h1>
            </div>
          </div>
        )}
        <ReferInvite />
      </div>
    </>
  );
};

export default Main;
