import React from "react";
import { Col, Row } from "react-bootstrap";
import bgImg from "../../../assets/images/common-bg-mobile.png";
import { useNavigate } from "react-router-dom";
import { getDefaultImgUrl } from "../../../utils/Constant";

const CompanyProfile = ({ heading, text, uuid, img }) => {
  const navigate = useNavigate();
  return (
    <div className="home-company-profile pointer" onClick={() => navigate(`/profile`)}>
      <div className="company-bg">
        <img src={bgImg} alt="company background" />
      </div>
      <div className="company-main">
        <Row>
          <Col md="5" className="relative pe-0">
            <div className="company-logo">
              <img src={img || getDefaultImgUrl(heading)} alt="company logo" />
            </div>
          </Col>
          <Col md="7" className="p-0">
            <h4 className="f-s-14 manrope-medium">{heading}</h4>
            <p className="f-s-12 manrope-regular">{text}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CompanyProfile;
