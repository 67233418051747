import React, { useEffect } from "react";
import CompanyProfile from "./ProfileCard";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { West } from "@mui/icons-material";
import { showModal } from "../../home/HomeAction";
import { modals } from "../../../utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../../utils/commonComponents/Modal";

const Main = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { modal, user } = useSelector((state) => state.HomeState);


  return (
    <>
      {modal === modals.EDIT_PROFILE_OPTIONS_MODAL && (
        <BasicModal
          open={modal}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      {modal === modals.EDIT_MEMBER_PROFILE_MODAL && (
        <BasicModal
          open={modal}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      {modal === modals.EDIT_PASSWORD_MODAL && (
        <BasicModal
          open={modal}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      <div className="my-profile-container container">
        {!isMobile && (
          <div className="networking-header flex-row gap-12 align-center mb-3">

            <div>
              <h1 className="f-s-24 manrope-bold">Profile</h1>
            </div>
          </div>
        )}
        <CompanyProfile
          img={user?.member?.profileImageUrl}
          heading={`${user?.member?.firstName} ${user?.member?.lastName}`}
          text={`${user?.member?.designation} | ${user?.member?.company?.companyName}`}
          phone={user?.member?.phone}
          email={user?.member?.user?.email}
        ></CompanyProfile>
        <div className="my-profile-btn">
          <CustomButton
            className={"primary-btn"}
            onClick={() =>
              dispatch(showModal(modals.EDIT_PROFILE_OPTIONS_MODAL))
            }
          >
            Edit Profile
          </CustomButton>
        </div>
      </div>
    </>
  );
};

export default Main;
