import React, { useEffect, useRef } from "react";
import AppInterface from "../../utils/commonComponents/AppInterface";
import { West } from "@mui/icons-material";
import CustomSearch from "../../utils/commonComponents/forms/CustomSearch";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../utils/commonComponents/forms/CustomButton";
import { useMediaQuery } from "@mui/material";
import CustomInput from "../../utils/commonComponents/forms/CustomInput";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getAdvancedSearch, getSearch } from "./SearchAction";

const ComponentName = (props) => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [recentSearch, setRecentSearch] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        onSubmit();
      }
    });
  }, [search]);

  const onSubmit = (item = null) => {
    let query = search;
    if (item) query = item;
    if (!query) return;

    if (!query) return; // Prevent empty search
    let recentSearchTemp = recentSearch?.filter((recent) => recent !== query); // Remove duplicates
    recentSearchTemp?.unshift(query); // Add new search at the top
    recentSearchTemp = recentSearchTemp?.slice(0, 5); // Keep only top 5
    setRecentSearch(recentSearchTemp);
    localStorage.setItem("recentSearch", JSON.stringify(recentSearchTemp));

    dispatch(getSearch(query)); // Dispatch the search action
    navigate(`/search/${query}`); // Navigate to the search page with the query
  };

  const searchRef = useRef(null);
  useEffect(() => {
    navigate("/search");
  }, [navigate]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.click();
    }
  }, [searchRef]);

  useEffect(() => {
    const savedRecentSearch = localStorage.getItem("recentSearch");
    if (savedRecentSearch) {
      setRecentSearch(JSON.parse(savedRecentSearch)); // Load recent searches from localStorage
    }
  }, []);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  if (params.get("advanced") === "true") {
    return <AdvancedSearch />;
  }

  return (
    <AppInterface
      hideSearch
      header={
        <>
          <div className="networking-header flex-row gap-12 align-center">
            <div
              className="pointer flex-row  align-center"
              onClick={() => navigate("/")}
            >
              <West></West>
            </div>
            <div className="mobile-search">
              <CustomSearch
                ref={searchRef}
                width={"100%"}
                search={search}
                setSearch={setSearch}
                placeholder={"Search"}
              ></CustomSearch>
            </div>
          </div>
        </>
      }
    >
      <div className="search-main-container container">
        {!isMobile && (
          <div className="networking-header flex-row gap-12 align-center mb-3">
            <div className="pointer" onClick={() => navigate("/")}>
              <West sx={{ fontSize: "24px" }}></West>
            </div>
            <div className="flex-row w-100">
              <div className="f-s-24 manrope-bold w-100 flex-row justify-space-between">
                Search{" "}
                <p
                  onClick={() => navigate("/search?advanced=true")}
                  className="error underline pointer"
                >
                  Advanced Search
                </p>
              </div>
            </div>
          </div>
        )}
        {!isMobile && (
          <div className="flex-row search-desktop">
            <div className="w-full pe-5">
              <CustomSearch
                ref={searchRef}
                width={"100%"}
                search={search}
                setSearch={setSearch}
                placeholder={"Search"}
              ></CustomSearch>
            </div>

            <div className="flex-row justify-center">
              <CustomButton
                width={"15rem"}
                onClick={() => onSubmit()}
                className={"primary-btn"}
              >
                Search
              </CustomButton>
            </div>
          </div>
        )}

        <div className="flex-col">
          {recentSearch && recentSearch.length > 0 ? (
            <div>
              <h3 className="f-s-14 manrope-semibold pt-5 pb-2 flex-row justify-space-between">
                Recent{" "}
                {isMobile && (
                  <p
                    onClick={() => navigate("/search?advanced=true")}
                    className="error underline pointer"
                  >
                    Advanced Search
                  </p>
                )}
              </h3>
              <div className="flex-col gap-12 mt-2 recent-searches">
                {recentSearch.map((item, index) => (
                  <div
                    key={index}
                    className="manrope-regular pointer f-s-12"
                    onClick={() => onSubmit(item)} // Call onSubmit with clicked recent search item
                  >
                    {item}
                    <hr className="my-2" />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <h3 className="f-s-14 manrope-semibold pt-5 pb-2 flex-row justify-space-between">
                Recent{" "}
                {isMobile && (
                  <p
                    onClick={() => navigate("/search?advanced=true")}
                    className="error underline pointer"
                  >
                    Advanced Search
                  </p>
                )}
              </h3>
              <div className="text-center my-5 py-5 no-recent-searches">
                No recent Search
              </div>
            </div>
          )}
        </div>

        {isMobile && (
          <div className="flex-row justify-center my-5">
            <CustomButton
              width={"100%"}
              onClick={() => onSubmit()}
              className={"primary-btn"}
            >
              Search
            </CustomButton>
          </div>
        )}
      </div>
    </AppInterface>
  );
};

export default ComponentName;

const AdvancedSearch = () => {
  const [searchData, setSearchData] = React.useState({
    firstName: "",
    lastName: "",
    industry: "",
    tags: "",
    companyName: "",
  });

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        onSubmit();
      }
    });
  }, [searchData]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const onSubmit = () => {
    const urlParams = Object.keys(searchData)
      .filter((key) => searchData[key] !== "")
      .map((key) => encodeURIComponent(searchData[key]));

    if (urlParams.length === 0) return;
    navigate(`/search/${urlParams.join("/")}?advanced=true`);
    dispatch(getAdvancedSearch(searchData));
  };

  return (
    <AppInterface
      hideSearch
      header={
        <>
          <div className="networking-header flex-row gap-12 align-center">
            <div
              className="pointer flex-row align-center"
              onClick={() => navigate("/search")}
            >
              <West></West>
            </div>
            <div className="flex-row justify-space-between align-center w-100">
              {" "}
              Advanced Search{" "}
              <p
                onClick={() => navigate("/search")}
                className="error underline pointer"
              >
                Basic Search
              </p>
            </div>
          </div>
        </>
      }
    >
      <div className="search-main-container container">
        {!isMobile && (
          <div className="networking-header flex-row gap-12 align-center mb-3">
            <div className="pointer" onClick={() => navigate("/search")}>
              <West sx={{ fontSize: "24px" }}></West>
            </div>
            <div className="flex-row w-100">
              <div className="f-s-24 manrope-bold w-100 flex-row justify-space-between">
                Advanced Search{" "}
                <p
                  onClick={() => navigate("/search")}
                  className="error underline pointer"
                >
                  Basic Search
                </p>
              </div>
            </div>
          </div>
        )}
        <div>
          <Col md="10">
            <Row>
              <Col md="6" className="mb-3">
                <CustomInput
                  important={false}
                  heading={"First Name"}
                  value={searchData.firstName}
                  onChange={(e) =>
                    setSearchData({ ...searchData, firstName: e.target.value })
                  }
                  placeholder={"Enter First Name"}
                />
              </Col>
              <Col md="6" className="mb-3">
                <CustomInput
                  important={false}
                  heading={"Last Name"}
                  value={searchData.lastName}
                  onChange={(e) =>
                    setSearchData({ ...searchData, lastName: e.target.value })
                  }
                  placeholder={"Enter Last Name"}
                />
              </Col>
              <Col md="6" className="mb-3">
                <CustomInput
                  important={false}
                  heading={"Industry"}
                  value={searchData.industry}
                  onChange={(e) =>
                    setSearchData({ ...searchData, industry: e.target.value })
                  }
                  placeholder={"Enter Industry"}
                />
              </Col>
              <Col md="6" className="mb-3">
                <CustomInput
                  important={false}
                  heading={"Company Name"}
                  value={searchData.companyName}
                  onChange={(e) =>
                    setSearchData({
                      ...searchData,
                      companyName: e.target.value,
                    })
                  }
                  placeholder={"Enter Company Name"}
                />
              </Col>
              <Col md="6" className="mb-3">
                <CustomInput
                  important={false}
                  heading={"Tags"}
                  value={searchData.tags}
                  onChange={(e) =>
                    setSearchData({ ...searchData, tags: e.target.value })
                  }
                  placeholder={"Tags"}
                />
              </Col>
            </Row>
          </Col>

          <div className="d-flex gap-2  mt-3">
            <CustomButton
              className={"primary-btn"}
              width={isMobile ? "100%" : "20%"}
              onClick={onSubmit}
            >
              Search
            </CustomButton>
          </div>
        </div>
      </div>
    </AppInterface>
  );
};
