import React from "react";
import { Col, Row } from "react-bootstrap";
import { Email, Phone } from "@mui/icons-material";
import bg from "../../../assets/images/common-bg-mobile.png";

const CompanyProfile = ({ img, heading, text, background, phone, email, tip }) => {
  return (
    <div className="home-company-profile">
      <div className="company-bg">
        <img src={bg} alt="profile banner" />
      </div>
      <div className="company-main px-0">
        <Row>
          <Col md="12" className="relative media">
            <div className="company-logo">
              <img src={img} alt="company logo" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="flex-row justify-space-between align-center p-md-4 mt-5 main-content">
              <div className="profile">
                <h4 className="f-s-16 manrope-medium">{heading}</h4>
                <p className="f-s-14 manrope-regular">{text}</p>
              </div>
              <Row className="gap-25">
                <Col className="">
                  <div className="display-chip flex-row gap-2 align-center">
                    <Phone />
                    <p onClick={() => {
                      if (phone === "12345678") return;
                      window.open(`tel:${phone}`);
                    }} className="f-s-16 manrope-regular pointer">{phone}</p>
                  </div>
                </Col>

                <Col className="">
                  <div className="display-chip flex-row gap-2 align-center">
                    <Email />
                    <p onClick={() => {
                      if (email === "xyz@gmail.com") return;
                      window.open(`mailto:${email}`);
                    }
                    } className="f-s-16 manrope-regular pointer">{email}</p>
                  </div>
                </Col>
              </Row>
            </div>
            {tip &&
              <div className="text-right profile-tip mb-2 me-5"><p className="error blue-0E1">{tip}</p></div>
            }
            <hr />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CompanyProfile;
